body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
    box-shadow: 0px 5px 10px #888888;
}

.userHome{
    padding: 2em 2em 2em 2em;
    border-radius: 25px; 
    background-color: #DCDCDC;
    box-shadow: 0px 5px 10px #888888;
}   

.instruction{
    text-align: center;
    padding: 0em 2em 0em 2em;
}