@import '../node_modules/antd/dist/antd.css';

.App {
  text-align: center;
}

.info {
    // center the text
    text-align: center;
    cursor: pointer;
}
